import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Future from "./Future/Future";
import Mcx from "./Mcx/Mcx";
import Options from "./Options/Options";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "./Watclist.css";
import Loading from "react-loading";
const backend = new Backend();

export default function Watclist() {
  const height = window.innerHeight;
  const [type, setType] = useState(1);
  const [fut, setFut] = useState([]);
  const [fut_search, setFut_Search] = useState([]);
  const [mcx, setMcx] = useState([]);
  const [mcx_search, setMcx_Search] = useState([]);
  const [fo, setFo] = useState([]);
  const [fo_search, setFo_Search] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadSymbol();
  }, []);

  const loadSymbol = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
    };

    backend.load_symbol(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setFut(r.fut);
        setFut_Search(r.fut);
        setMcx(r.mcx);
        setMcx_Search(r.mcx);
        setFo(r.fo);
        setFo_Search(r.fo);
      } else if (r.logout == 1) {
        localStorage.removeItem("token");
        localStorage.removeItem("id");
        localStorage.removeItem("username");
        localStorage.removeItem("server");
        localStorage.removeItem("AuthState");
        window.location = "/";
      } else {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      }
    });
  };

  const searchSymbol = (a) => {
    if (type == 1) {
      if (a.length > 1) {
        var data = fut_search.filter((x) =>
          x.symbol_display.toLowerCase().includes(a.toLowerCase())
        );
        setFut(data);
      } else {
        setFut(fut_search);
      }
    } else if (type == 2) {
      if (a.length > 1) {
        var data = mcx_search.filter((x) =>
          x.symbol_display.toLowerCase().includes(a.toLowerCase())
        );
        setMcx(data);
      } else {
        setMcx(mcx_search);
      }
    } else if (type == 3) {
      if (a.length > 1) {
        var data = fo_search.filter((x) =>
          x.symbol_display.toLowerCase().includes(a.toLowerCase())
        );
        setFo(data);
      } else {
        setFo(fo_search);
      }
    }
  };

  return (
    <div style={{ height: height - 130 }}>
      <div
        style={{
          display: "flex",
          height: 55,
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: Dark.background,
        }}
      >
        <div
          onClick={() => setType(1)}
          style={{
            height: 40,
            width: 100,
            backgroundColor: type == 1 ? Dark.primary : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            color: Dark.text,
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: Dark.primary,
          }}
        >
          FUTURE
        </div>
        <div
          onClick={() => setType(2)}
          style={{
            height: 40,
            width: 100,
            backgroundColor: type == 2 ? Dark.primary : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            color: Dark.text,
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: Dark.primary,
          }}
        >
          MCX
        </div>
        <div
          onClick={() => setType(3)}
          style={{
            height: 40,
            width: 100,
            backgroundColor: type == 3 ? Dark.primary : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            color: Dark.text,
            borderStyle: "solid",
            borderWidth: 2,
            borderColor: Dark.primary,
          }}
        >
          OPTIONS
        </div>
        <div className="loginInputWatclist">
          <InputText
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchSymbol(e.target.value);
            }}
            placeholder="Enter Symbol Name"
          />
        </div>
      </div>

      <div key={type} style={{ height: "88%" }}>
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" height={35} width={35} color={Dark.text} />
          </div>
        ) : (
          <div>
            <div>{type == 1 ? <Future key={fut} fut={fut} /> : null}</div>
            <div>{type == 2 ? <Mcx key={mcx} mcx={mcx} /> : null}</div>
            <div>{type == 3 ? <Options key={fo} fo={fo} /> : null}</div>
          </div>
        )}
      </div>
    </div>
  );
}
