import React from "react";
import RightCard from "./RightCard/RightCard";
import { Dark } from "../../../Theme/Theme";
import Marquee from "./Marquee";
import "./Home.css";
export default function Home() {
  const height = window.innerHeight;

  return (
    <div
      style={{
        backgroundColor: Dark.secondary,
        height: height - 140,
      }}
    >
      <div
        style={{
          height: "45px",
          margin: 10,
          backgroundColor: Dark.background,
          borderRadius: 10,
        }}
      >
        <Marquee />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 3.5,
            margin: 10,
            borderRadius: 10,
            backgroundColor: Dark.background,
          }}
        >
          <RightCard />
        </div>

      </div>
    </div>
  );
}
