import React, { useState } from "react";
import Backend from "../../../../../../Backend/Backend";
import { Dark } from "../../../../../../Theme/Theme";
import { toast } from "react-toast";
import { InputText } from "primereact/inputtext";
import "../Header.css";
import { Dropdown } from "primereact/dropdown";

import Loading from "react-loading";
const bakend = new Backend();

export default function ExpirySymbol(props) {
  const [loading, setLoading] = useState(false);
  const [selectedsymbol, setSelectedSymbol] = useState(null);
  const [expiry_date, setexpiry_date] = useState("");

  const symbol_list = [
    { name: "GOLD", value: "GOLD" },
    { name: "GOLDM", value: "GOLDM" },
    { name: "SILVER", value: "SILVER" },
    { name: "SILVERM", value: "SILVERM" },
    { name: "ZINC", value: "ZINC" },
    { name: "LEAD", value: "LEAD" },
    { name: "CRUDEOIL", value: "CRUDEOIL" },
    { name: "COPPER", value: "COPPER" },
    { name: "ALUMINIUM", value: "ALUMINIUM" },
    { name: "NATURALGAS", value: "NATURALGAS" },
  ];


  const expiry = () => {
    if(selectedsymbol == null){
      toast.error("Select Symbol First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    }else if (expiry_date == null || expiry_date == "") {
      toast.error("Select Month First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        expiry_date: expiry_date,
        symbol : selectedsymbol
      };

      bakend.symbol_mcx_expiry(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          props.close();
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div>

<div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          SYMBOL : {selectedsymbol}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedsymbol}
              onChange={(e) => setSelectedSymbol(e.value)}
              options={symbol_list}
              optionLabel="name"
              placeholder="Select a Symbol"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          EXPIRY DATE :
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div className="loginInputFilterSymbol">
              <InputText
                value={expiry_date}
                onChange={(e) => {
                  setexpiry_date(e.target.value);
                }}
                placeholder="Enter Expiry Date"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={loading ? null : expiry}
        style={{
          padding: 10,
          backgroundColor: Dark.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 7,
          color: Dark.text,
          marginTop: 20,
        }}
      >
        {loading ? (
          <div>
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          </div>
        ) : (
          "SAVE"
        )}
      </div>
    </div>
  );
}
