import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import LiveTrade from "./LiveTrade";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { useSelector } from "react-redux";
import LivePl from "../../../../Healer/LivePl";
import { NumericFormat } from "react-number-format";
const backend = new Backend();

export default function RightCard() {
  const [trade, setTrade] = useState(0);

  const total = useSelector((state) => state.total);
  const btotal = useSelector((state) => state.btotal);
  const theme = useSelector((state) => state.theme);

  const [admin, setAdmin] = useState(0);
  const [supers, setSuper] = useState(0);
  const [master, setMaster] = useState(0);
  const [client, setClient] = useState(0);
  const [booked, setBooked] = useState(0);
  const [standing, setStanding] = useState([]);

  useEffect(() => {
    loadMore();
    loadLeftCard();
    loadLiveTrade();
  }, []);

  const loadMore = () => {
    setInterval(() => {
      loadLiveTrade();
      loadLeftCard();
    }, 30000);
  };

  const loadLiveTrade = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
    };

    backend.live_trade(data).then((r) => {
      if (r.error == "False") {
        setTrade(r.trade);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadLeftCard = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_all_data(data).then((r) => {
      if (r.error == "False") {
        setAdmin(r.admin);
        setSuper(r.super);
        setMaster(r.master);
        setClient(r.client);
        setStanding(r.standing);
        setBooked(r.profit_loss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  let m2m = parseFloat(
    parseFloat(parseFloat(btotal) + parseFloat(total))
  ).toFixed(0);

  return (
    <div>
      {standing.length > 0
        ? standing.map((i) => {
            return <LivePl item={i} key={i.id} total={standing.length} />;
          })
        : null}
      <div
        style={{
          padding: 12,
          color: Dark.text,
          fontSize: 22,
          backgroundColor: Dark.primary,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          display: "flex",
        }}
      >
        <div style={{ flex: 1, textTransform: "uppercase", fontSize: 16 }}>
          Recent Trading Activities
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> M2M </div>
          <div style={{ color: m2m > 0 ? Dark.buy : Dark.sell, fontSize: 14 }}>
            <NumericFormat
              value={m2m}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> BROKERAGE </div>
          <div style={{ color: Dark.sell, fontSize: 14 }}>
            <NumericFormat
              value={parseFloat(btotal).toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> P/L </div>
          <div
            style={{ color: total > 0 ? Dark.buy : Dark.sell, fontSize: 14 }}
          >
            <NumericFormat
              value={parseFloat(total).toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> BOOKED </div>
          <div
            style={{ color: booked > 0 ? Dark.buy : Dark.sell, fontSize: 14 }}
          >
            <NumericFormat
              value={parseFloat(booked).toFixed(0)}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> ADMIN </div>
          <div style={{ color: Dark.text, fontSize: 14 }}>
            {parseFloat(admin).toFixed(0)}
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> SUPER </div>
          <div style={{ color: Dark.text, fontSize: 14 }}>
            {parseFloat(supers).toFixed(0)}
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> MASTER </div>
          <div style={{ color: Dark.text, fontSize: 14 }}>
            {parseFloat(master).toFixed(0)}
          </div>
        </div>

        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            color: Dark.text,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: theme == 0 ? Dark.background : Light.background,
            width: 120,
            height: 50,
            marginLeft: 10,
          }}
        >
          <div style={{ fontSize: 12 }}> CLIENT </div>
          <div style={{ color: Dark.text, fontSize: 14 }}>
            {parseFloat(client).toFixed(0)}
          </div>
        </div>
      </div>
      <div>
        <LiveTrade trade={trade} key={trade} />
      </div>
    </div>
  );
}
