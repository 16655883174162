import React, { useState } from "react";
import AdminList from "./AdminList/AdminList";
import Admins from "./Admin/Admin";
import AddAdmin from "./AddAdmin/AddAdmin";
import Summary from "./Summary/Summary";
import { Dark } from "../../../Theme/Theme";
export default function Admin() {
  const height = window.innerHeight;
  const [type, setType] = useState(0);
  const [admin, setAdmin] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewAdmin = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: "100%",
        height: height / 1.24,
        display: "flex",
      }}
    >
      <div style={{ flex: 3, margin: 10 }}>
        {type == 0 ? <Summary /> : null}
        {type == 2 ? (
          <AdminList
            admin={admin}
            reload={() => {
              setupdate(1);
            }}
          />
        ) : null}
        {type == 1 ? (
          <AddAdmin
            reload={() => {
              setupdate(1);
              ShowSummary();
            }}
          />
        ) : null}
      </div>
      <div style={{ flex: 1, backgroundColor: Dark.background }}>
        <Admins
          Add={AddNewAdmin}
          ShowSummary={ShowSummary}
          setAdmin={(a) => {
            setAdmin(a);
            setType(2);
          }}
          key={update}
          admin={admin}
        />
      </div>
    </div>
  );
}
