import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Edit from "./Edit/Edit";
import Margin from "./Margin/Margin";
import Ledger from "./Ledger/Ledger";
import LoginLog from "./LoginLog/LoginLog";
import Future from "./Future/Future";
import Mcx from "./Mcx/Mcx";
import Options from "./Options/Options";
import Block from "./Block/Block";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";

const backend = new Backend();
export default function AdminList(props) {
  const admin = props.admin;

  const [type, setType] = useState(0);
  const [adminS, setAdminS] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAdmin();
  }, []);

  const loadAdmin = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      admin_id: admin.id,
    };

    backend.single_admin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setAdminS(r.admin);
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      key={admin.id}
      style={{
        height: "78vh",
        backgroundColor: Dark.background,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          height: 65,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 2,
          borderBottomColor: Dark.primary,
        }}
      >
        <div style={{ color: Dark.text, flex: 1 }}>
          <div style={{ fontSize: 18 }}>Username : {admin.username}</div>
          <div style={{ fontSize: 12, paddingTop: 7 }}>Name : {admin.name}</div>
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 2.5,
            display: "flex",
            height: "100%",
          }}
        >
          <div
            onClick={() => setType(0)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 0 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            EDIT
          </div>
          <div
            onClick={() => setType(1)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 1 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MARGIN
          </div>
          <div
            onClick={() => setType(2)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 2 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            LEDGER
          </div>
          <div
            onClick={() => setType(3)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 3 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            LOGIN LOG
          </div>
          <div
            onClick={() => setType(4)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 4 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            FUTURE
          </div>
          <div
            onClick={() => setType(5)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 5 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            MCX
          </div>
          <div
            onClick={() => setType(6)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 6 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            OPTIONS
          </div>

          <div
            onClick={() => setType(7)}
            style={{
              flex: 1,
              fontSize: 14,
              color: type == 7 ? Dark.primary : Dark.text,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            BLOCK
          </div>
        </div>
      </div>

      <div style={{ height: "90%" }}>
        {type == 0 ? <Edit admin={admin} reload={props.reload} /> : null}
        {type == 1 ? <Margin admin={admin} reload={props.reload} /> : null}
        {type == 2 ? <Ledger admin={admin} /> : null}
        {type == 3 ? <LoginLog admin={admin} /> : null}

        {type == 4 ? <Future admin={admin} /> : null}
        {type == 5 ? <Mcx admin={admin} /> : null}
        {type == 6 ? <Options admin={admin} /> : null}
        {type == 7 ? <Block admin={admin} /> : null}
      </div>
    </div>
  );
}
