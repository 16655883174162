import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Logo from "../../../Image/Logo.png";
import LogoSub from "../../../Image/LogoSub.png";
import { BiLogOut } from "react-icons/bi";
import "./Topview.css";
import { Link } from "react-router-dom";
export default function TopView() {
  const path = window.location.pathname;

  const [select, setSelect] = useState(false);

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("AuthState");
    window.location = "/";
  };

  return (
    <div
      className="topview"
      style={{ height: 100, backgroundColor: Dark.background, display: "flex" }}
    >
      <Link
        to={"/"}
        onClick={() => {
          setSelect(!select);
        }}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={Logo} style={{ height: "70px" }} />
        <img src={LogoSub} style={{ height: "30px" }} />
      </Link>
      <div
        style={{
          flex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          className="link"
          to={"Home"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/" || path == "/Home" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          HOME
        </Link>

        <Link
          className="link"
          to={"Watclist"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/Watclist" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          WATCHLIST
        </Link>

        <Link
          className="link"
          to={"Admin"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Admin" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          ADMIN
        </Link>
        {/* 
        <Link
          className="link"
          to={"Super"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Master" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          SUPER
        </Link>

        <Link
          className="link"
          to={"Master"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Master" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          MASTER
        </Link>

        <Link
          className="link"
          to={"Client"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor: path == "/Master" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          CLIENT
        </Link> */}

        <Link
          className="link"
          to={"Position"}
          onClick={() => {
            setSelect(!select);
          }}
          style={{
            width: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: Dark.text,
            backgroundColor:
              path == "/Position" ? Dark.primary : Dark.background,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          POSITION
        </Link>

        <div class="dropdown">
          <div class="dropbtn"> ORDER </div>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"Trade"}
            >
              TRADE
            </Link>
            {/* <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"Pending"}
            >
              PENDING
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"Holding"}
            >
              HOLDING
            </Link> */}
          </div>
        </div>

        {/* <div class="dropdown">
          <button class="dropbtn"> BILL</button>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"ClientBill"}
            >
              CLIENT
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"BrokerBill"}
            >
              BROKER
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"SubBrokerBill"}
            >
              SUBBROKER
            </Link>
          </div>
        </div> */}

        <div class="dropdown">
          <div
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/FutureSymbol" ||
                path == "/McxSymbol" ||
                path == "/OptionsSymbol"
                  ? Dark.primary
                  : Dark.background,
            }}
          >
            {" "}
            SYMBOL{" "}
          </div>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"FutureSymbol"}
            >
              FUTURE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"McxSymbol"}
            >
              MCX
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"OptionsSymbol"}
            >
              OPTIONS
            </Link>
          </div>
        </div>

        <div class="dropdown">
          <div
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/FutureClosing" ||
                path == "/McxClosing" ||
                path == "/OptionsClosing"
                  ? Dark.primary
                  : Dark.background,
            }}
          >
            {" "}
            CLOSING{" "}
          </div>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"FutureClosing"}
            >
              FUTURE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"McxClosing"}
            >
              MCX
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"OptionsClosing"}
            >
              OPTIONS
            </Link>
          </div>
        </div>

        <div class="dropdown">
          <div
            class="dropbtn"
            style={{
              backgroundColor:
                path == "/FutureTime" ||
                path == "/McxTime" ||
                path == "/OptionsTime"
                  ? Dark.primary
                  : Dark.background,
            }}
          >
            {" "}
            TIME{" "}
          </div>
          <div class="dropdown-content">
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"FutureTime"}
            >
              FUTURE
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"McxTime"}
            >
              MCX
            </Link>
            <Link
              onClick={() => {
                setSelect(!select);
              }}
              className="link"
              to={"OptionsTime"}
            >
              OPTIONS
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div onClick={Logout}>
            <BiLogOut color={Dark.text} fontSize={30} />
          </div>
        </div>
      </div>
    </div>
  );
}
