import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import Loading from "react-loading";
const backend = new Backend();
export default function Margin(props) {
  const client = props.client;

  const [clientS, setClientS] = useState("");

  const [loading, setLoading] = useState(false);

  const [show_edit_client_margin, setshow_edit_client_margin] = useState(false);

  const [multiplier_future, setMultiplierFuture] = useState("");
  const [multiplier_mcx, setMultiplierMcx] = useState("");
  const [multiplier_options, setMultiplierOptions] = useState("");
  const [future_hold, setFutureHold] = useState("");
  const [mcx_hold, setMcxHold] = useState("");
  const [options_hold, setOptionsHold] = useState("");
  const [fatak, setFatak] = useState("");

  const [tpassword, setTPassword] = useState("");

  useEffect(() => {
    loadClientSingle();
  }, []);

  const loadClientSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client.id,
    };

    backend.single_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientS(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_client_margin = () => {
    setLoading(true);
    setshow_edit_client_margin(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: clientS.id,
      multiplier_future:
        multiplier_future == "" ? clientS.multiplier_future : multiplier_future,
      multiplier_mcx:
        multiplier_mcx == "" ? clientS.multiplier_mcx : multiplier_mcx,
      multiplier_options:
        multiplier_options == ""
          ? clientS.multiplier_options
          : multiplier_options,
      fatak: fatak == "" ? clientS.deposit_stop : fatak,
      future_hold: future_hold == "" ? clientS.future_hold : future_hold,
      mcx_hold: mcx_hold == "" ? clientS.mcx_hold : mcx_hold,
      options_hold: options_hold == "" ? clientS.options_hold : options_hold,
      tpassword: tpassword,
    };

    backend.edit_client_margin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        loadClientSingle();
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Future Margin</div>
            <div>{clientS.multiplier_future}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_future}
              onChange={(e) => {
                setMultiplierFuture(e.target.value);
              }}
              type="number"
              placeholder="Future"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Mcx Margin</div>
            <div>{clientS.multiplier_mcx}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_mcx}
              onChange={(e) => {
                setMultiplierMcx(e.target.value);
              }}
              type="number"
              placeholder="Mcx"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Options Margin</div>
            <div>{clientS.multiplier_options}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_options}
              onChange={(e) => {
                setMultiplierOptions(e.target.value);
              }}
              type="number"
              placeholder="Options"
            />
          </div>
        </div>

        {/* <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>FATAK</div>
            <div>{clientS.deposit_stop}%</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={fatak}
              onChange={(e) => {
                setFatak(e.target.value);
              }}
              type="number"
              placeholder="FATAK"
            />
          </div>
        </div> */}
      </div>

      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Future Carry Margin</div>
            <div>{clientS.future_hold}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={future_hold}
              onChange={(e) => {
                setFutureHold(e.target.value);
              }}
              type="number"
              placeholder="Future"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Mcx Carry Margin</div>
            <div>{clientS.mcx_hold}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={mcx_hold}
              onChange={(e) => {
                setMcxHold(e.target.value);
              }}
              type="number"
              placeholder="Mcx"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Options Carry Margin</div>
            <div>{clientS.options_hold}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={options_hold}
              onChange={(e) => {
                setOptionsHold(e.target.value);
              }}
              type="number"
              placeholder="Options"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          onClick={() => (loading ? null : setshow_edit_client_margin(true))}
          style={{
            color: Dark.text,
            height: 40,
            width: 200,
            backgroundColor: Dark.primary,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Loading type="spin" height={25} width={25} color={Dark.text} />
          ) : (
            "SAVE"
          )}
        </div>
      </div>

      <Dialog
        visible={show_edit_client_margin}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_client_margin(false)}
      >
        <TPassword
          Update={edit_client_margin}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}
