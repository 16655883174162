import React, { useState } from "react";
import WatclistCard from "../WatclistCard";
import { Dark } from "../../../../Theme/Theme";
export default function Options(props) {
  const height = window.innerHeight;

  return (
    <div style={{ margin: 10, height: height / 1.4 }}>
      {props.fo.length > 0 ? (
        <div
          className="scroll"
          style={{
            height: "100%",
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            overflowY: "scroll",
          }}
        >
          {props.fo.map((i, t) => {
            return <WatclistCard item={i} />;
          })}
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
            {" "}
            Nothing here
          </div>
          <div
            style={{
              color: Dark.text,
            }}
          >
            Use the search bar to add instruments.
          </div>
        </div>
      )}
    </div>
  );
}
