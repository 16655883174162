import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import LeftCard from "./LeftCard/LeftCard";
import RightCard from "./RightCard/RightCard";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "../../../Healer/Loading/Loading";
import LivePl from "../../../Healer/LivePl";

const backend = new Backend();

export default function Position() {
  const [loading, setLoading] = useState(false);
  const [standing, setStanding] = useState([]);
  const [booked, setBooked] = useState(0);

  const [admin_id, setAdminId] = useState("");
  const [super_id, setSuperId] = useState("");
  const [master_id, setMasterId] = useState("");
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
    };

    backend.load_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadAdminStanding = (a) => {
    setAdminId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: a,
      symbol: symbol,
    };
    backend.load_admin_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSuperStanding = (a) => {
    setSuperId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      super_id: a,
      symbol: symbol,
    };
    backend.load_super_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMasterStanding = (a) => {
    setMasterId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      master_id: a,
      symbol: symbol,
    };
    backend.load_master_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientStanding = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      client_id: a,
      symbol: symbol,
    };
    backend.load_client_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolStanding = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      client_id: client_id,
      symbol: a,
    };
    backend.load_symbol_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setStanding(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
      }}
    >
      <div key={[standing, client_id, symbol]} style={{ flex: 1 }}>
        {standing.length > 0
          ? standing.map((i) => {
              return <LivePl item={i} key={i.id} total={standing.length} />;
            })
          : null}
        <LeftCard booked={booked} />
      </div>
      <div
        style={{ flex: 5, backgroundColor: Dark.background, height: "100%" }}
      >
        <Menu />
        <div className="scroll" style={{ height: "92%", overflowY: "scroll" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading />
            </div>
          ) : standing.length > 0 ? (
            standing.map((i, t) => {
              return <PositionCard item={i} total={standing.length} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>
      {/* <div style={{ flex: 1 }}>
        <RightCard
          reload={loadStanding}
          admin={loadAdminStanding}
          super={loadSuperStanding}
          master={loadMasterStanding}
          client={loadClientStanding}
          symbol={loadSymbolStanding}
        />
      </div> */}
    </div>
  );
}
