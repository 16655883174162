import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import AddSymbol from "./AddSymbol/AddSymbol";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { confirmAlert } from "react-confirm-alert";
import Loading from "react-loading";
import ExpirySymbol from "./ExpirySymbol/ExpirySymbol";
import "./Header.css";
const backend = new Backend();
export default function Header(props) {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [loading, setLoading] = useState(false);

  const symbol_options_delete_all = (a) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to delete all options symbol.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              symbol: a,
            };

            backend.symbol_options_delete_all(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        borderRadius: 10,
        margin: 5,
        backgroundColor: Dark.background,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          paddingLeft: 20,
          textTransform: "uppercase",
        }}
      >
        Options Symbol All List
      </div>

      <div
        style={{
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setShow1(true)}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "EXPIRY SYMBOL"
          )}
        </div>

        <div
          onClick={() => setShow(true)}
          style={{
            height: 40,
            width: 120,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "ADD SYMBOL"
          )}
        </div>

        <div class="dropdown">
          <div class="dropbtndelete">
            {loading ? (
              <Loading type="spin" color={Dark.text} height={20} width={20} />
            ) : (
              "DELETE SYMBOL"
            )}
          </div>
          <div class="dropdown-content">
            <div
              onClick={() => {
                symbol_options_delete_all("NIFTY");
              }}
            >
              NIFTY
            </div>
            <div
              onClick={() => {
                symbol_options_delete_all("BANKNIFTY");
              }}
            >
              BANKNIFTY
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="ADD OPTIONS SMBOL"
        visible={show}
        className="modal"
        style={{ width: "40vw", height: "90vh" }}
        onHide={() => setShow(false)}
      >
        <AddSymbol
          close={() => {
            setShow(false);
            props.load();
          }}
        />
      </Dialog>
      <Dialog
        header="EXPIRY FUTURE SMBOL"
        visible={show1}
        className="modal"
        style={{ width: "40vw", height: "40vh" }}
        onHide={() => setShow1(false)}
      >
        <ExpirySymbol
          close={() => {
            setShow1(false);
            props.load();
          }}
        />
      </Dialog>
    </div>
  );
}
