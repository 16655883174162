import React, { useState } from "react";
// import SuperList from "./SuperList/SuperList";
// import Supers from "./Super/Super";
// import AddSuper from "./AddSuper/AddSuper";
// import { Dark } from "../../../Theme/Theme";
import Summary from "./Summary/Summary";
export default function Super() {
  const height = window.innerHeight;
  const [type, setType] = useState(0);
  const [supers, setSuper] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewSuper = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: "100%",
        height: height / 1.24,
        display: "flex",
      }}
    >
      <div style={{ flex: 3, margin: 10 }}>
        {type == 0 ? <Summary /> : null}
        {/* {type == 2 ? (
          <SuperList
            super={supers}
            reload={() => {
              setupdate(1);
            }}
          />
        ) : null}
        {type == 1 ? (
          <AddSuper
            reload={() => {
              setupdate(1);
              ShowSummary();
            }}
          />
        ) : null} */}
      </div>
      {/* <div style={{ flex: 1, backgroundColor: Dark.background }}>
        <Supers
          Add={AddNewSuper}
          ShowSummary={ShowSummary}
          setSuper={(a) => {
            setSuper(a);
            setType(2);
          }}
          key={update}
          super={supes}
        />
      </div> */}
    </div>
  );
}
