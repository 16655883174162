import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
const backend = new Backend();

export default function Active(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [tpassword, setTPassword] = useState("");

  const Update = () => {
    setLoading(true);
    setShow(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: props.client_id,
      fresh_limit: props.status == 0 ? 1 : 0,
      tpassword: tpassword,
    };

    backend.client_fresh_limit_status(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div>
      <div
        key={props.status}
        onClick={() => setShow(true)}
        style={{
          padding: 10,
          backgroundColor: props.status == 0 ? Dark.sell : Dark.buy,
          paddingLeft: 20,
          paddingRight: 20,
          borderRadius: 7,
          color: Dark.text,
        }}
      >
        {loading ? (
          <Loading type="spin" height={25} width={25} color={Dark.text} />
        ) : (
          "Fresh Limit"
        )}
      </div>
      <Dialog
        visible={show}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShow(false)}
      >
        <TPassword Update={Update} tpassword={(a) => setTPassword(a)} />
      </Dialog>
    </div>
  );
}
