import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import BrokerCard from "./BrokerCard";
import { InputText } from "primereact/inputtext";
import "./Broker.css";
const backend = new Backend();

export default function Broker(props) {
  const admin = props.admin;
  const [loading, setLoading] = useState("");
  const [adminS, setAdminS] = useState("");
  const [broker, setBroker] = useState("");
  const [brokerlist, setBrokerList] = useState([]);

  const [broker_id, setBroker_id] = useState(0);

  const [fut_volume_broker, setfut_volume_broker] = useState("");
  const [fut_lot_broker, setfut_lot_broker] = useState("");

  const [mcx_volume_broker, setmcx_volume_broker] = useState("");
  const [mcx_lot_broker, setmcx_lot_broker] = useState("");

  const [fo_volume_broker, setfo_volume_broker] = useState("");
  const [fo_lot_broker, setfo_lot_broker] = useState("");

  const [plsharing, setplsharing] = useState("");

  useEffect(() => {
    load_broker();
    loadAdminSingle();
  }, []);

  const load_broker = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: admin.id,
    };
    backend.admin_broker(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setBroker(r.broker);
        setBrokerList(r.broker_list);
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadAdminSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: admin.id,
    };

    backend.single_admin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setAdminS(r.admin);
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const UpdateBroker = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: admin.id,
      broker_id: broker_id,
    };
    backend.admin_broker_update(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        load_broker();
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const RemoveBroker = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: admin.id,
      broker_id: broker_id,
    };
    backend.admin_broker_remove(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        load_broker();
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const UpdateBrokerBrokerage = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: admin.id,
      broker_id: broker_id,
      fut_volume_broker: fut_volume_broker,
      fut_lot_broker: fut_lot_broker,
      mcx_volume_broker: mcx_volume_broker,
      mcx_lot_broker: mcx_lot_broker,
      fo_volume_broker: fo_volume_broker,
      fo_lot_broker: fo_lot_broker,
      plsharing: plsharing,
    };
    backend.admin_broker_remove(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        load_broker();
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          flex: 2,
          height: "95%",
        }}
      >
        <div
          style={{
            backgroundColor: Dark.primary,
            padding: 10,
            color: Dark.text,
            textTransform: "uppercase",
          }}
        >
          YOUR BROKER
        </div>
        {broker == 0 ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here !
            </div>
            <div style={{ color: Dark.text }}>No Active Broker Found</div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: Dark.secondary,
              margin: 10,
              height: "90%",
              borderRadius: 10,
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textTransform: "uppercase",
              }}
            >
              <div style={{ color: Dark.text }}>BROKER NAME : </div>
              <div style={{ color: Dark.text }}>{" " + broker.name + " "}</div>
            </div>
            <div
              style={{
                height: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: 20,
                }}
              >
                <div style={{ color: Dark.text, fontSize: 13 }}>
                  FUTURE VOLUME : {adminS.fut_volume_broker}{" "}
                </div>
                <div style={{ color: Dark.text, marginTop: 5, fontSize: 13 }}>
                  FUTURE LOT: {adminS.fut_lot_broker}{" "}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: 20,
                }}
              >
                <div style={{ color: Dark.text, fontSize: 13 }}>
                  MCX VOLUME : {adminS.mcx_volume_broker}{" "}
                </div>
                <div style={{ color: Dark.text, marginTop: 5, fontSize: 13 }}>
                  MCX LOT: {adminS.mcx_lot_broker}{" "}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  paddingLeft: 20,
                }}
              >
                <div style={{ color: Dark.text, fontSize: 13 }}>
                  OPTIONS VOLUME : {adminS.fo_volume_broker}{" "}
                </div>
                <div style={{ color: Dark.text, marginTop: 5, fontSize: 13 }}>
                  OPTIONS LOT: {adminS.fo_lot_broker}{" "}
                </div>
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: Dark.text,
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ color: Dark.text }}>FUTURE</div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Min Volume : {adminS.fut_min_volume}
                  </div>
                  <InputText
                    value={fut_volume_broker}
                    onChange={(e) => {
                      setfut_volume_broker(e.target.value);
                    }}
                    type="number"
                    placeholder="Enter Brokerage"
                  />
                </div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Min Lot : {adminS.fut_min_lot}
                  </div>
                  <InputText
                    value={fut_lot_broker}
                    onChange={(e) => {
                      setfut_lot_broker(e.target.value);
                    }}
                    placeholder="Enter Brokerage"
                  />
                </div>
              </div>
              <div
                style={{
                  color: Dark.text,
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ color: Dark.text, marginTop: 5 }}>MCX</div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Min Volume : {adminS.mcx_min_volume}
                  </div>
                  <InputText
                    value={mcx_volume_broker}
                    onChange={(e) => {
                      setmcx_volume_broker(e.target.value);
                    }}
                    placeholder="Enter Brokerage"
                  />
                </div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Lot : {adminS.mcx_min_lot}
                  </div>
                  <InputText
                    value={mcx_lot_broker}
                    onChange={(e) => {
                      setmcx_lot_broker(e.target.value);
                    }}
                    placeholder="Enter Brokerage"
                  />
                </div>
              </div>
              <div
                style={{
                  color: Dark.text,
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ color: Dark.text, marginTop: 5 }}>OPTIONS</div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Volume : {adminS.fo_min_volume}
                  </div>
                  <InputText
                    value={fo_volume_broker}
                    onChange={(e) => {
                      setfo_volume_broker(e.target.value);
                    }}
                    placeholder="Enter Brokerage"
                  />
                </div>
                <div className="loginInputEditBrokerAdmin">
                  <div style={{ textAlign: "center", marginTop: 5 }}>
                    Lot : {adminS.fo_min_lot}
                  </div>
                  <InputText
                    value={fo_lot_broker}
                    onChange={(e) => {
                      setfo_lot_broker(e.target.value);
                    }}
                    placeholder="Enter Brokerage"
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                height: 55,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="loginInputEditBrokerAdmin">
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 5,
                    color: Dark.text,
                  }}
                >
                  Max ProfitLoss Sharing : {adminS.sharing}
                </div>
                <InputText
                  value={plsharing}
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setplsharing(e.target.value);
                  }}
                  placeholder="Enter ProfitLoss Sharing"
                />
              </div>
            </div>

            <div
              style={{
                height: 70,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                onClick={RemoveBroker}
                style={{
                  backgroundColor: Dark.sell,
                  padding: 12,
                  borderRadius: 10,
                  color: Dark.text,
                }}
              >
                REMOVE BROKER
              </div>

              <div
                onClick={UpdateBrokerBrokerage}
                style={{
                  backgroundColor: Dark.primary,
                  padding: 12,
                  borderRadius: 10,
                  color: Dark.text,
                }}
              >
                UPDATE BROKER
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            flex: 1,
            height: "95%",
          }}
        >
          <div
            style={{
              backgroundColor: Dark.primary,
              padding: 10,
              color: Dark.text,
              textTransform: "uppercase",
            }}
          >
            Broker List
          </div>
          <div
            style={{
              backgroundColor: Dark.secondary,
              margin: 10,
              height: "60%",
              borderRadius: 10,
              overflowY: "scroll",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Loading type="spin" height={30} width={30} color={Dark.text} />
              </div>
            ) : brokerlist.length > 0 ? (
              brokerlist.map((i, t) => {
                return (
                  <BrokerCard
                    item={i}
                    broker_id={broker_id}
                    update={(a) => setBroker_id(a)}
                  />
                );
              })
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}
                >
                  {" "}
                  Nothing here !
                </div>
                <div style={{ color: Dark.text }}>No Active Broker Found</div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={UpdateBroker}
              style={{
                height: 40,
                width: 200,
                display: "flex",
                backgroundColor: Dark.primary,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 8,
                color: Dark.text,
              }}
            >
              UPDATE BROKER
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
