import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import LeftCard from "./LeftCard/LeftCard";
import RightCard from "./RightCard/RightCard";
import Menu from "./Menu";
import TradeCard from "./TradeCard/TradeCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "../../../Healer/Loading/Loading";

const backend = new Backend();

export default function Pending() {
  const [loading, setLoading] = useState(false);
  const [trade, setTrade] = useState([]);

  const [brokerage, setBrokerage] = useState(0);
  const [profit_loss, setProfit_loss] = useState(0);
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");

  useEffect(() => {
    loadTrade();
  }, []);

  const loadTrade = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      filter: "TODAY",
    };

    backend.load_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientTrade = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: a,
      symbol: symbol,
      filter: "TODAY",
    };
    backend.load_client_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolTrade = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: client_id,
      symbol: a,
      filter: "TODAY",
    };
    backend.load_symbol_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setBrokerage(r.brokerage);
        setProfit_loss(r.profit_loss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "82vh",
      }}
    >
      <div style={{ flex: 1 }}>
        <LeftCard brokerage={brokerage} profit_loss={profit_loss} />
      </div>
      <div
        style={{ flex: 5, backgroundColor: Dark.background, height: "100%" }}
      >
        <Menu />
        <div style={{ height: "92%", overflowY: "scroll" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading />
            </div>
          ) : trade.length > 0 ? (
            trade.map((i, t) => {
              return <TradeCard item={i} index={t} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Pending Found.</div>
            </div>
          )}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <RightCard
          reload={loadTrade}
          client={loadClientTrade}
          symbol={loadSymbolTrade}
        />
      </div>
    </div>
  );
}
