import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function Menu() {
  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ADMIN
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SUPER
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        MASTER
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        CLIENT
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SYMBOL
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        QTY
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        BUY/SELL
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        RATE
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TYPE
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        P/L
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        DATE
      </div>
    </div>
  );
}
