import React from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import moment from "moment";
import { useSelector } from "react-redux";

export default function TradeCard(props) {
  const i = props.item;

  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.background,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.admin_username}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.super_username}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.master_username}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.client_username}
      </div>
      <div
        style={{
          flex: 1,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 15,
          flexDirection: "column",
        }}
      >
        <div>{i.symbol}</div>
        <div style={{ fontSize: 9, paddingTop: 3 }}>
          {moment(i.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.qty}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: i.buy_sell == 0 ? Dark.buy : Dark.sell,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        {i.rate}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color:
            i.type == "NEW SAUDA"
              ? Dark.primary
              : i.type == "SAUDA ADDED"
              ? Dark.buy
              : i.type == "SAUDA REMOVE"
              ? Dark.sell
              : i.type == "SAUDA SQ.Off"
              ? Dark.sell
              : Dark.sell,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 12,
        }}
      >
        {i.type}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: i.profit_loss > 0 ? Dark.buy : Dark.sell,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.profit_loss}
      </div>
      <div
        style={{
          flex: 1,
          height: "45px",
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          fontSize: 12,
        }}
      >
        {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
      </div>
    </div>
  );
}
