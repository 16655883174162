import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import moment from "moment";
export default function LoginLogCard(props) {
  const i = props.item;
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 0.2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.location == null ? "No Found" : i.location}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.device}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {i.ip}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {moment(i.date_created).format("DD-MM-YYYY hh:mm:ss")}
      </div>
    </div>
  );
}
