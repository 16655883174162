import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import AddSymbol from "./AddSymbol/AddSymbol";
import ExpirySymbol from './ExpirySymbol/ExpirySymbol'
export default function Header(props) {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  return (
    <div
      style={{
        borderRadius: 10,
        margin: 5,
        backgroundColor: Dark.background,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          paddingLeft: 20,
          textTransform: "uppercase",
        }}
      >
        Mcx Symbol All List
      </div>

      <div
        style={{
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setShow1(true)}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          SYMBOL EXPIRY
        </div>

        <div
          onClick={() => setShow(true)}
          style={{
            height: 40,
            width: 120,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          ADD SYMBOL
        </div>
      </div>

      <Dialog
        header="ADD MCX SMBOL"
        visible={show}
        className="modal"
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setShow(false)}
      >
        <AddSymbol
          close={() => {
            setShow(false);
            props.load();
          }}
        />
      </Dialog>
      <Dialog
        header="EXPIRY FUTURE SMBOL"
        visible={show1}
        className="modal"
        style={{ width: "40vw", height: "45vh" }}
        onHide={() => setShow1(false)}
      >
        <ExpirySymbol
          close={() => {
            setShow1(false);
            props.load();
          }}
        />
      </Dialog>
    </div>
  );
}
