import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function LeftCard(props) {
  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          height: 30,
          backgroundColor: Dark.background,
          marginBottom: 10,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        Overview
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>LIMIT</div>
        <div
          style={{
            color: Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          1
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>PENDING</div>
        <div
          style={{
            color: Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          1
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.sell }}>STOCK/LOSS</div>
        <div style={{ color: Dark.sell, marginTop: 10, fontSize: 22 }}>1</div>
      </div>
    </div>
  );
}
