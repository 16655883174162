import React from "react";
import Menu from "./Menu";
import TradeCard from "./TradeCard";
import { Dark } from "../../../../Theme/Theme";
export default function LiveTrade(props) {
  return (
    <div>
      <Menu />
      <div
        className="scroll"
        style={{
          display: "flex",
          height: "55vh",
          overflowY: "scroll",
          flexDirection: "column",
        }}
      >
        {props.trade.length > 0 ? (
          props.trade.map((i) => {
            return <TradeCard item={i} />;
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Trade Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
