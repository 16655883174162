import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function SummaryCard(props) {
  const item = props.item;

  return (
    <div
      style={{
        height: "55px",
        width: "100%",
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        {item.username}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.profit_loss_day > 0 ? Dark.buy : Dark.sell,
        }}
      >
        {item.profit_loss_day}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.profit_loss_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        {item.profit_loss_week}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.sharing_day > 0 ? Dark.buy : Dark.sell,
        }}
      >
        {item.sharing_day} ({item.sharing}%)
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.sharing_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        {item.sharing_week} ({item.sharing}%)
      </div>
    </div>
  );
}
