import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import "./Symbol.css";
import Loading from "react-loading";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
const backend = new Backend();

export default function SymbolCard(props) {
  const item = props.item;

  const [loading, setLoading] = useState(false);
  const [brokerage, setBrokerage] = useState("");

  const UpdateSymbol = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id : props.client_id,
      symbol: item.name,
      brokerage: brokerage,
    };

    backend.symbol_brokerage_update(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.loadSymbol();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        margin: 10,
        padding: 10,
        backgroundColor: Dark.background,
        height: 55,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
        {item.name} : {item.brokerage}
      </div>
      <div style={{ flex: 1 }}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="loginInputDarkSymbol"
          >
            <InputText
              value={brokerage}
              onChange={(e) => {
                setBrokerage(e.target.value);
              }}
              style={{
                width: "150px",
              }}
              placeholder="Enter Brokerage"
            />

            <div
              onClick={loading ? null : UpdateSymbol}
              style={{
                padding: 10,
                backgroundColor: Dark.primary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 20,
                paddingLeft: 30,
                paddingRight: 30,
                borderRadius: 7,
                color: Dark.text,
              }}
            >
              {loading ? (
                <div>
                  <Loading
                    type="spin"
                    color={Dark.text}
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                "SAVE"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
