import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function LeftCard(props) {
  var profit_loss = props.profit_loss;
  var brokerage = props.brokerage;
  let m2m = 0;

  m2m = profit_loss > 0 ? profit_loss + brokerage : profit_loss - brokerage;

  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          height: 30,
          backgroundColor: Dark.background,
          marginBottom: 10,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        Overview
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>Profit/Loss</div>
        <div
          style={{
            color: profit_loss > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          {profit_loss == null ? 0 : profit_loss}
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>M2M</div>
        <div
          style={{
            color: m2m > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          {m2m}
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.sell }}>BROKERAGE</div>
        <div style={{ color: Dark.sell, marginTop: 10, fontSize: 22 }}>
          {brokerage == null ? 0 : brokerage}
        </div>
      </div>
    </div>
  );
}
