import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function Menu() {
  return (
    <div
      style={{
        height: 40,
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 2,
        borderBottomColor: Dark.primary,
        display: "flex",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        NAME
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        ORDER QTY
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        TOTAL ORDER
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
