import React, { useEffect, useState } from "react";
import Marquees from "react-fast-marquee";
import { Dark } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import UpdateMarquee from "./UpdateMarquee/UpdateMarquee";
import "./UpdateMarquee/Update.css";
const backend = new Backend();

export default function Marquee() {
  const [marquees, setMarquee] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    load_marquee();
  }, []);

  const load_marquee = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
    };
    backend.load_marquee(data).then((r) => {
      if (r.error == "False") {
        setMarquee(r.marquee.text);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      onClick={() => setShow(true)}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "45px",
      }}
    >
      <Marquees style={{ fontSize: 22, color: Dark.text }}>
        {marquees == undefined ? "NO HEADLINE FOUND" : marquees}
      </Marquees>

      <Dialog
        visible={show}
        showHeader={false}
        className="modalNew"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShow(false)}
      >
        <UpdateMarquee load={load_marquee} close={() => setShow(false)} />
      </Dialog>
    </div>
  );
}
