import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dialog } from "primereact/dialog";
import TPassword from "../../../../../Healer/TPassword/TPassword";
import Loading from "react-loading";
const backend = new Backend();
export default function Margin(props) {
  const admin = props.admin;

  const [adminS, setAdminS] = useState("");

  const [loading, setLoading] = useState(false);

  const [show_edit_admin_margin, setshow_edit_admin_margin] = useState(false);

  const [multiplier_future, setMultiplierFuture] = useState("");
  const [multiplier_mcx, setMultiplierMcx] = useState("");
  const [multiplier_options, setMultiplierOptions] = useState("");
  const [sharing, setSharing] = useState("");

  const [tpassword, setTPassword] = useState("");

  useEffect(() => {
    loadAdminSingle();
  }, []);

  const loadAdminSingle = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      admin_id: admin.id,
    };

    backend.single_admin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setAdminS(r.admin);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const edit_admin_margin = () => {
    setLoading(true);
    setshow_edit_admin_margin(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      admin_id: adminS.id,
      multiplier_future:
        multiplier_future == "" ? adminS.multiplier_future : multiplier_future,
      multiplier_mcx:
        multiplier_mcx == "" ? adminS.multiplier_mcx : multiplier_mcx,
      multiplier_options:
        multiplier_options == ""
          ? adminS.multiplier_options
          : multiplier_options,
      sharing: sharing == "" ? adminS.sharing : sharing,
      tpassword: tpassword,
    };

    backend.edit_admin_margin(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        setMultiplierFuture("");
        setMultiplierMcx("");
        setMultiplierOptions("");
        setSharing("");
        loadAdminSingle();
        props.reload();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Future Margin</div>
            <div>
              {adminS.multiplier_future == null ? 0 : adminS.multiplier_future}
            </div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_future}
              onChange={(e) => {
                setMultiplierFuture(e.target.value);
              }}
              type="number"
              placeholder="Future"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Mcx Margin</div>
            <div>
              {adminS.multiplier_mcx == null ? 0 : adminS.multiplier_mcx}
            </div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_mcx}
              onChange={(e) => {
                setMultiplierMcx(e.target.value);
              }}
              type="number"
              placeholder="Mcx"
            />
          </div>
        </div>

        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Options Margin</div>
            <div>
              {adminS.multiplier_options == null
                ? 0
                : adminS.multiplier_options}
            </div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={multiplier_options}
              onChange={(e) => {
                setMultiplierOptions(e.target.value);
              }}
              type="number"
              placeholder="Options"
            />
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: 5 }}>Sharing</div>
            <div>{adminS.sharing}</div>
          </div>
          <div className="loginInputEditAdmin">
            <InputText
              value={sharing}
              onChange={(e) => {
                setSharing(e.target.value);
              }}
              type="number"
              placeholder="Sharing"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          onClick={() => (loading ? null : setshow_edit_admin_margin(true))}
          style={{
            color: Dark.text,
            height: 40,
            width: 200,
            backgroundColor: Dark.primary,
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading ? (
            <Loading type="spin" height={25} width={25} color={Dark.text} />
          ) : (
            "SAVE"
          )}
        </div>
      </div>

      <Dialog
        visible={show_edit_admin_margin}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setshow_edit_admin_margin(false)}
      >
        <TPassword
          Update={edit_admin_margin}
          tpassword={(a) => setTPassword(a)}
        />
      </Dialog>
    </div>
  );
}
