import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocketContect from "./Component/Healer/SocketContect";

import NiftyBankNifty from "./Component/Screen/MainStack/NiftyBankNifty/NiftyBankNifty";

import TopView from "./Component/Screen/MainStack/TopView/TopView";

import { Dark } from "./Component/Theme/Theme";
import { ToastContainer } from "react-toast";
import Login from "./Component/Screen/LoginStack/Login/Login";

import Home from "./Component/Screen/MainStack/Home/Home";
import Watclist from "./Component/Screen/MainStack/Watclist/Watclist";

import Admin from "./Component/Screen/MainStack/Admin/Admin";
import Super from "./Component/Screen/MainStack/Super/Super";
import Master from "./Component/Screen/MainStack/Master/Master";
import Client from "./Component/Screen/MainStack/Master/Master";

import Position from "./Component/Screen/MainStack/Position/Position";

import Trade from "./Component/Screen/MainStack/Trade/Trade";
import Pending from "./Component/Screen/MainStack/Pending/Pending";
import Holding from "./Component/Screen/MainStack/Holding/Holding";

import FutureSymbol from "./Component/Screen/MainStack/Symbol/Future/Future";
import McxSymbol from "./Component/Screen/MainStack/Symbol/Mcx/Mcx";
import OptionsSymbol from "./Component/Screen/MainStack/Symbol/Options/Options";

import FutureClosing from "./Component/Screen/MainStack/Closing/Future/Future";
import McxClosing from "./Component/Screen/MainStack/Closing/Mcx/Mcx";
import OptionsClosing from "./Component/Screen/MainStack/Closing/Options/Options";

import FutureTime from "./Component/Screen/MainStack/Time/Future/Future";
import McxTime from "./Component/Screen/MainStack/Time/Mcx/Mcx";
import OptionsTime from "./Component/Screen/MainStack/Time/Options/Options";

import useWindowSize from "./Component/Healer/WindowSize";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./App.css";

import "react-confirm-alert/src/react-confirm-alert.css";

import io from "socket.io-client";

var socket = io("https://sauda.io:40000");

export default function App() {
  const height = useWindowSize();

  return (
    <div style={{ backgroundColor: Dark.secondary, height: height }}>
      <ToastContainer className="toplevel" position="top-right" delay={3000} />
      {localStorage.getItem("AuthState") == "1" ? (
        <BrowserRouter>
          <SocketContect.Provider value={socket}>
            <NiftyBankNifty />
            <TopView />
            <div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/Home" element={<Home />} />

                <Route path="/Watclist" element={<Watclist />} />

                <Route path="/Admin" element={<Admin />} />

                <Route path="/Super" element={<Super />} />

                <Route path="/Master" element={<Master />} />

                <Route path="/Client" element={<Client />} />

                <Route path="/Position" element={<Position />} />
                <Route path="/Trade" element={<Trade />} />
                <Route path="/Pending" element={<Pending />} />
                <Route path="/Holding" element={<Holding />} />

                <Route path="/FutureSymbol" element={<FutureSymbol />} />
                <Route path="/McxSymbol" element={<McxSymbol />} />
                <Route path="/OptionsSymbol" element={<OptionsSymbol />} />

                <Route path="/FutureClosing" element={<FutureClosing />} />
                <Route path="/McxClosing" element={<McxClosing />} />
                <Route path="/OptionsClosing" element={<OptionsClosing />} />

                <Route path="/FutureTime" element={<FutureTime />} />
                <Route path="/McxTime" element={<McxTime />} />
                <Route path="/OptionsTime" element={<OptionsTime />} />
              </Routes>
            </div>
          </SocketContect.Provider>
        </BrowserRouter>
      ) : (
        <div>
          <Login />
        </div>
      )}
    </div>
  );
}
