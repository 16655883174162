import React from "react";
import { Dark } from "../../../Theme/Theme";
export default function Holding() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "82vh",
      }}
    >
      <div
        style={{ flex: 4, backgroundColor: Dark.background, height: "100%" }}
      >
        <div style={{ margin: 10 }}>
          <div
            style={{
              height: 30,
              display: "flex",
              backgroundColor: Dark.secondary,
              borderRadius: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
            <div
              style={{
                color: "#fff",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              hi
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
