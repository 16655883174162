import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import Client from "./Client/Client";
import Symbol from "./Symbol/Symbol";

export default function RightCard(props) {
  const [client, setClient] = useState(false);
  const [symbol, setSymbol] = useState(false);

  const [client_data, setClientData] = useState("");
  const [symbol_data, setSymbolData] = useState("");

  const Clear = () => {
    setClientData("");
    setSymbolData("");
    props.client("");
    props.symbol("");
    props.reload();
  };

  return (
    <div style={{ margin: 10 }}>
      <div
        style={{
          height: 50,
          backgroundColor: Dark.background,
          marginBottom: 10,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div style={{ flex: 1 }}>Filter</div>
        <div onClick={Clear} style={{ color: Dark.sell }}>
          Clear
        </div>
      </div>
      <div
        onClick={() => setClient(true)}
        style={{
          height: "80px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.primary }}>Client</div>
        <div style={{ color: "#fff", marginTop: 10, fontSize: 22 }}>
          {client_data == "" ? "No Select" : client_data.username}
        </div>
      </div>
      <div
        onClick={() => setSymbol(true)}
        style={{
          height: "80px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.primary }}>Symbol</div>
        <div style={{ color: "#fff", marginTop: 10, fontSize: 22 }}>
          {symbol_data == "" ? "No Select" : symbol_data}
        </div>
      </div>

      <Dialog
        header={"Client List"}
        visible={client}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setClient(false)}
      >
        <Client
          client={props.client}
          updateclient={(a) => setClientData(a)}
          close={() => setClient(false)}
        />
      </Dialog>

      <Dialog
        header={"Symbol List"}
        visible={symbol}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setSymbol(false)}
      >
        <Symbol
          symbol={props.symbol}
          updatesymbol={(a) => setSymbolData(a)}
          close={() => setSymbol(false)}
        />
      </Dialog>
    </div>
  );
}
