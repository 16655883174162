import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import SocketContext from "../../../Healer/SocketContect";

export default function Card(props) {
  const i = props.item;

  const socket = useContext(SocketContext);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high, sethigh] = useState(0);
  const [low, setlow] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpne] = useState(0);
  const [close, setClose] = useState(0);

  const [upbid, setUpBid] = useState(false);
  const [upask, setUpAsk] = useState(false);

  const trade = (msg) => {
    if (msg == null) {
    } else {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
      }
      if (high !== msg.High) {
        sethigh(msg.High);
      }
      if (low !== msg.Low) {
        setlow(msg.Low);
      }
      if (open !== msg.Open) {
        setOpne(msg.Low);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);

      if (msg.Bid > bid) {
        setUpBid(true);
      }
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      if (msg.Bid > bid) {
        setUpAsk(true);
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", i.symbol_id);
    socket.on("trade" + i.symbol_id, trade);
    socket.on("bidask" + i.symbol_id, bidask);
    return () => {
      socket.off("trade" + i.symbol_id, trade);
      socket.off("bidask" + i.symbol_id, bidask);
    };
  }, []);
  return (
    <div
      key={i.symbol_id}
      style={{
        paddingRight: 20,
        fontSize: 15,
        display: "flex",
        color: Dark.text,
        textAlign:"center"
      }}
    >
      {i.symbol_display} {ltp}
      <div style={{ color: chng > 0 ? Dark.buy : Dark.sell, marginLeft: 5 }}>
        {parseFloat(chng).toFixed(2)}
        {"  "}
        {"(" + parseFloat(percent).toFixed(2) + "%)"}
      </div>
    </div>
  );
}
