import React from "react";
import Splesh from "../../Image/Splesh.gif";

export default function Loading(props) {
  return (
    <div>
      <img src={Splesh} height={props.height} />
    </div>
  );
}
