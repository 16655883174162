import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { confirmAlert } from "react-confirm-alert";
import Loading from "react-loading";

import { Calendar } from "primereact/calendar";

const backend = new Backend();
export default function Header(props) {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const update_closing_future = () => {
    confirmAlert({
      title: "Confirm to Update",
      message: "Are you sure to update rate future symbol.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              date : date
            };

            backend.update_closing_future(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const closing_symbol_get_future = () => {
    confirmAlert({
      title: "Confirm to Run",
      message: "Are you sure to run closing future symbol sq mode.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.closing_future(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const reset_balance = () => {
    confirmAlert({
      title: "Confirm to Reset Balance",
      message: "Are you sure to reset balance.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
            };

            backend.reset_balance(data).then((r) => {
              setLoading(false);
              if (r.error == "False") {
                toast.success(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        borderRadius: 10,
        margin: 5,
        backgroundColor: Dark.background,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          paddingLeft: 20,
          textTransform: "uppercase",
        }}
      >
        Future Symbol All List
      </div>

      <div
        style={{
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          <Calendar
            dateFormat="dd/mm/yy"
            value={date}
            onChange={(e) => setDate(e.value)}
          />
        </div>
        <div
          onClick={update_closing_future}
          style={{
            height: 40,
            width: 140,
            backgroundColor: Dark.buy,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "UPDATE RATE"
          )}
        </div>
        <div
          onClick={closing_symbol_get_future}
          style={{
            height: 40,
            width: 180,
            backgroundColor: Dark.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "RUN WEEKLY CLOSING"
          )}
        </div>

        <div
        onClick={reset_balance}
          style={{
            height: 40,
            width: 160,
            backgroundColor: Dark.sell,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            marginRight: 20,
          }}
        >
          {loading ? (
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          ) : (
            "UPDATE BALANCE"
          )}
        </div>
      </div>
    </div>
  );
}
