import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { useSelector } from "react-redux";

export default function LeftCard(props) {
  const total = useSelector((state) => state.total);
  const btotal = useSelector((state) => state.btotal);
  let m2m = 0;
  total > 0
    ? (m2m = parseFloat(total) + parseFloat(btotal))
    : (m2m = parseFloat(total) - parseFloat(btotal));

  return (
    <div style={{ margin: 10 }}>
      {/* {standing.length > 0
        ? standing.map((i) => {
            return <LivePl item={i} key={i.id} total={standing.length} />;
          })
        : null} */}
      <div
        style={{
          height: 30,
          backgroundColor: Dark.background,
          marginBottom: 10,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        Overview
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>Profit/Loss</div>
        <div
          style={{
            color: total > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          {parseFloat(total).toFixed(2)}
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.text }}>M2M</div>
        <div
          style={{
            color: m2m > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          {parseFloat(m2m).toFixed(2)}
        </div>
      </div>
      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: Dark.sell }}>BROKERAGE</div>
        <div style={{ color: Dark.sell, marginTop: 10, fontSize: 22 }}>
          {parseFloat(btotal).toFixed(2)}
        </div>
      </div>

      <div
        style={{
          height: "100px",
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 10,
        }}
      >
        <div style={{ color: props.booked > 0 ? Dark.buy : Dark.sell }}>
          BOOKED
        </div>
        <div
          style={{
            color: props.booked > 0 ? Dark.buy : Dark.sell,
            marginTop: 10,
            fontSize: 22,
          }}
        >
          {props.booked == null ? 0 : parseFloat(props.booked).toFixed(2)}
        </div>
      </div>
    </div>
  );
}
