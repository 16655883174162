import React from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";

export default function SymbolCard(props) {
  const item = props.item;
  return (
    <div
      style={{
        color: Dark.text,
        height: 55,
        margin: 10,
        backgroundColor: Dark.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        paddingLeft: 10,
      }}
    >
      <div style={{ flex: 1 }}>
        <div>{item.symbol}</div>
        <div style={{ fontSize: 12, paddingTop: 5, color: Dark.sell }}>
          {moment(item.expiry_date).format("DD-MMM")} - (
          {moment(item.expiry_date).format("YYYY-MM-DD HH:mm:ss")})
        </div>
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {parseFloat(item.rate).toFixed(2)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {item.close_rate == null ? 0 : parseFloat(item.close_rate).toFixed(2)}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>
        {moment(item.date_created).format("DD-MM-YYYY HH:mm:ss")}
      </div>
    </div>
  );
}
