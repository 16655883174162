// const api_link = "http://localhost:50019";
const api_link = "https://sauda.io:50019";
const EntryKeyGod = "74b80f6a42923f5839247665b0e8344d";

export default class Backend {
  login(data) {
    return fetch(api_link + "/login", {
      method: "POST",
      headers: {
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
        id: data.id,
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_marquee(data) {
    return fetch(api_link + "/load_marquee", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_all_data(data) {
    return fetch(api_link + "/load_all_data", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  fsq_off(data) {
    return fetch(api_link + "/fsq_off", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  live_trade(data) {
    return fetch(api_link + "/live_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol(data) {
    return fetch(api_link + "/load_symbol", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary_admin(data) {
    return fetch(api_link + "/load_summary_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary_super(data) {
    return fetch(api_link + "/load_summary_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary_master(data) {
    return fetch(api_link + "/load_summary_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_summary_client(data) {
    return fetch(api_link + "/load_summary_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status_admin(data) {
    return fetch(api_link + "/check_status_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status_super(data) {
    return fetch(api_link + "/check_status_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_client(data) {
    return fetch(api_link + "/add_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  check_status_client(data) {
    return fetch(api_link + "/check_status_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client(data) {
    return fetch(api_link + "/load_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_admin(data) {
    return fetch(api_link + "/load_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_super(data) {
    return fetch(api_link + "/load_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_master(data) {
    return fetch(api_link + "/load_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_client(data) {
    return fetch(api_link + "/load_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  single_admin(data) {
    return fetch(api_link + "/single_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  single_client(data) {
    return fetch(api_link + "/single_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_client_margin(data) {
    return fetch(api_link + "/edit_client_margin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_admin_details(data) {
    return fetch(api_link + "/edit_admin_details", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_admin_brokerage(data) {
    return fetch(api_link + "/edit_admin_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_admin_margin(data) {
    return fetch(api_link + "/edit_admin_margin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_ledger(data) {
    return fetch(api_link + "/admin_ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_client_details(data) {
    return fetch(api_link + "/edit_client_details", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_client_brokerage(data) {
    return fetch(api_link + "/edit_client_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_ledger(data) {
    return fetch(api_link + "/client_ledger", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  admin_login_log(data) {
    return fetch(api_link + "/admin_login_log", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_future(data) {
    return fetch(api_link + "/admin_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  admin_mcx(data) {
    return fetch(api_link + "/admin_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  admin_options(data) {
    return fetch(api_link + "/admin_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_future(data) {
    return fetch(api_link + "/client_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_mcx(data) {
    return fetch(api_link + "/client_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_options(data) {
    return fetch(api_link + "/client_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_edit_qty(data) {
    return fetch(api_link + "/client_edit_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_reset_qty(data) {
    return fetch(api_link + "/client_reset_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_edit_qty(data) {
    return fetch(api_link + "/admin_edit_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  admin_reset_qty(data) {
    return fetch(api_link + "/admin_reset_qty", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_status(data) {
    return fetch(api_link + "/admin_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_status(data) {
    return fetch(api_link + "/client_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_future_status(data) {
    return fetch(api_link + "/admin_future_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_mcx_status(data) {
    return fetch(api_link + "/admin_mcx_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_options_status(data) {
    return fetch(api_link + "/admin_options_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_fresh_limit_status(data) {
    return fetch(api_link + "/admin_fresh_limit_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_autosq_status(data) {
    return fetch(api_link + "/admin_autosq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_midlimit_status(data) {
    return fetch(api_link + "/admin_midlimit_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_onlysq_status(data) {
    return fetch(api_link + "/admin_onlysq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_future_status(data) {
    return fetch(api_link + "/client_future_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_mcx_status(data) {
    return fetch(api_link + "/client_mcx_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_options_status(data) {
    return fetch(api_link + "/client_options_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_fresh_limit_status(data) {
    return fetch(api_link + "/client_fresh_limit_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_autosq_status(data) {
    return fetch(api_link + "/client_autosq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_lot_volume_status(data) {
    return fetch(api_link + "/client_lot_volume_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_midlimit_status(data) {
    return fetch(api_link + "/client_midlimit_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_onlysq_status(data) {
    return fetch(api_link + "/client_onlysq_status", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_block_admin(data) {
    return fetch(api_link + "/load_block_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_block_add(data) {
    return fetch(api_link + "/admin_block_add", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  admin_block_remove(data) {
    return fetch(api_link + "/admin_block_remove", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_block(data) {
    return fetch(api_link + "/load_block", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_block_add(data) {
    return fetch(api_link + "/client_block_add", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_block_remove(data) {
    return fetch(api_link + "/client_block_remove", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_index(data) {
    return fetch(api_link + "/load_index", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_standing(data) {
    return fetch(api_link + "/load_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_standing_trade(data) {
    return fetch(api_link + "/load_standing_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  admin_standing(data) {
    return fetch(api_link + "/admin_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  super_standing(data) {
    return fetch(api_link + "/super_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  master_standing(data) {
    return fetch(api_link + "/master_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_standing(data) {
    return fetch(api_link + "/client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_admin_standing(data) {
    return fetch(api_link + "/load_admin_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_super_standing(data) {
    return fetch(api_link + "/load_super_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_master_standing(data) {
    return fetch(api_link + "/load_master_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_standing(data) {
    return fetch(api_link + "/load_client_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_standing(data) {
    return fetch(api_link + "/symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_standing(data) {
    return fetch(api_link + "/load_symbol_standing", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_trade(data) {
    return fetch(api_link + "/load_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  client_trade(data) {
    return fetch(api_link + "/client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_trade(data) {
    return fetch(api_link + "/symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_client_trade(data) {
    return fetch(api_link + "/load_client_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_symbol_trade(data) {
    return fetch(api_link + "/load_symbol_trade", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_brokerage(data) {
    return fetch(api_link + "/symbol_brokerage", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_brokerage_update(data) {
    return fetch(api_link + "/symbol_brokerage_update", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_add_future(data) {
    return fetch(api_link + "/symbol_add_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_get_future(data) {
    return fetch(api_link + "/symbol_get_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_future_delete_all(data) {
    return fetch(api_link + "/symbol_future_delete_all", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_future_sq_all_active(data) {
    return fetch(api_link + "/symbol_future_sq_all_active", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_future_sq_all_deactive(data) {
    return fetch(api_link + "/symbol_future_sq_all_deactive", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  client_report(data) {
    return fetch(api_link + "/client_report", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_add_mcx(data) {
    return fetch(api_link + "/symbol_add_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_get_mcx(data) {
    return fetch(api_link + "/symbol_get_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_mcx_delete(data) {
    return fetch(api_link + "/symbol_mcx_delete", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_mcx_sq_active(data) {
    return fetch(api_link + "/symbol_mcx_sq_active", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_mcx_sq_deactive(data) {
    return fetch(api_link + "/symbol_mcx_sq_deactive", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_add_options(data) {
    return fetch(api_link + "/symbol_add_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_get_options(data) {
    return fetch(api_link + "/symbol_get_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_options_delete_all(data) {
    return fetch(api_link + "/symbol_options_delete_all", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_options_sq_all_active(data) {
    return fetch(api_link + "/symbol_options_sq_all_active", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_options_sq_all_deactive(data) {
    return fetch(api_link + "/symbol_options_sq_all_deactive", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_marquee(data) {
    return fetch(api_link + "/update_marquee", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_admin(data) {
    return fetch(api_link + "/add_admin", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_super(data) {
    return fetch(api_link + "/add_super", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_master(data) {
    return fetch(api_link + "/add_master", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_client(data) {
    return fetch(api_link + "/add_client", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  symbol_future_expiry(data) {
    return fetch(api_link + "/symbol_future_expiry", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_mcx_expiry(data) {
    return fetch(api_link + "/symbol_mcx_expiry", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  symbol_options_expiry(data) {
    return fetch(api_link + "/symbol_options_expiry", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_closing_future(data) {
    return fetch(api_link + "/update_closing_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  closing_future(data) {
    return fetch(api_link + "/closing_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  update_closing_mcx(data) {
    return fetch(api_link + "/update_closing_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  closing_mcx(data) {
    return fetch(api_link + "/closing_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  update_closing_options(data) {
    return fetch(api_link + "/update_closing_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  closing_options(data) {
    return fetch(api_link + "/closing_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }

  closing_symbol_get_future(data) {
    return fetch(api_link + "/closing_symbol_get_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  closing_symbol_get_mcx(data) {
    return fetch(api_link + "/closing_symbol_get_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  closing_symbol_get_options(data) {
    return fetch(api_link + "/closing_symbol_get_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  reset_balance(data) {
    return fetch(api_link + "/reset_balance", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_time_future(data) {
    return fetch(api_link + "/load_time_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_time_future(data) {
    return fetch(api_link + "/update_time_future", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_time_mcx(data) {
    return fetch(api_link + "/load_time_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_time_mcx(data) {
    return fetch(api_link + "/update_time_mcx", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_time_options(data) {
    return fetch(api_link + "/load_time_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_time_options(data) {
    return fetch(api_link + "/update_time_options", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_future_state(data) {
    return fetch(api_link + "/update_future_state", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_mcx_state(data) {
    return fetch(api_link + "/update_mcx_state", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
  update_options_state(data) {
    return fetch(api_link + "/update_options_state", {
      method: "POST",
      headers: {
        auth: data.token,
        id: data.id,
        username: data.username,
        EntryKeyGod: EntryKeyGod,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    }).then((response) => response.json());
  }
}
