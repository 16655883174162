import React from "react";
import { Dark } from "../../../../Theme/Theme";
import Status from "./Status";
export default function ClientCard(props) {
  const i = props.item;
  return (
    <div
      onClick={() => props.setClient(i)}
      style={{
        backgroundColor: Dark.secondary,
        color: "#fff",
        padding: 5,
        height: 120,
        marginBottom: 15,
        borderRadius: 10,
        display: "flex",
        borderStyle: "solid",
        borderColor: props.client.id == i.id ? Dark.primary : Dark.secondary,
      }}
    >
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Server - {i.server}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>
          Username - {i.username}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 15 }}>Name - {i.name}</div>
        <div style={{ paddingBottom: 5, fontSize: 12 }}>
          Balance : {i.deposit_current}
        </div>
        <div style={{ paddingBottom: 5, fontSize: 10 }}>
          Margin : F : {i.multiplier_future}, M : {i.multiplier_mcx}, O : {i.multiplier_options}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Future :{" "}
          <div style={{ color: i.future == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.future == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Mcx :{" "}
          <div style={{ color: i.mcx == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.mcx == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Options :{" "}
          <div style={{ color: i.options == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.options == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Fresh Limit :{" "}
          <div style={{ color: i.fresh_limit == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.fresh_limit == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div style={{ paddingBottom: 5, fontSize: 12, display: "flex" }}>
          Mid Limit :{" "}
          <div style={{ color: i.mid_limit == 0 ? Dark.sell : Dark.buy }}>
            {" "}
            {i.mid_limit == 0 ? " Deactive" : " Active"}{" "}
          </div>
        </div>

        <div
          style={{
            paddingBottom: 5,
            fontSize: 12,
            color: Dark.primary,
          }}
        >
          <Status id={i.id} />
        </div>
      </div>
    </div>
  );
}
