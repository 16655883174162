import React, { useState } from "react";
import Backend from "../../../../../../Backend/Backend";
import { Dark } from "../../../../../../Theme/Theme";
import { toast } from "react-toast";
import "../Header.css";
import { Dropdown } from "primereact/dropdown";

import Loading from "react-loading";
const bakend = new Backend();

export default function AddSymbol(props) {
  const [loading, setLoading] = useState(false);

  const [selectedmonth, setSelectedMonth] = useState(null);
  const [selectedyear, setSelectedYear] = useState(null);
  const [selectedsymbol, setSelectedSymbol] = useState(null);
  const symbol_list = [
    { name: "GOLD", value: "GOLD" },
    { name: "GOLDM", value: "GOLDM" },
    { name: "SILVER", value: "SILVER" },
    { name: "SILVERM", value: "SILVERM" },
    { name: "ZINC", value: "ZINC" },
    { name: "LEAD", value: "LEAD" },
    { name: "CRUDEOIL", value: "CRUDEOIL" },
    { name: "COPPER", value: "COPPER" },
    { name: "ALUMINIUM", value: "ALUMINIUM" },
    { name: "NATURALGAS", value: "NATURALGAS" },
  ];
  const month_list = [
    { name: "January", value: "JAN" },
    { name: "February", value: "FEB" },
    { name: "March", value: "MAR" },
    { name: "April", value: "APR" },
    { name: "May", value: "MAY" },
    { name: "June", value: "JUN" },
    { name: "July", value: "JUL" },
    { name: "August", value: "AUG" },
    { name: "September", value: "SEP" },
    { name: "October", value: "OCT" },
    { name: "November", value: "NOV" },
    { name: "December", value: "DEC" },
  ];

  const year_list = [
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "30", value: "30" },
    { name: "31", value: "31" },
    { name: "32", value: "32" },
    { name: "33", value: "33" },
    { name: "34", value: "34" },
  ];

  const add_symbol = () => {
    if (selectedsymbol == null) {
      toast.error("Select Symbol First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (selectedmonth == null) {
      toast.error("Select Month First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (selectedyear == null) {
      toast.error("Select Year First", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        username: localStorage.getItem("username"),
        server: localStorage.getItem("server"),
        symbol : selectedsymbol,
        month: selectedmonth,
        year: selectedyear,
      };

      bakend.symbol_add_mcx(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          props.close();
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div>
      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          SYMBOL : {selectedsymbol}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedsymbol}
              onChange={(e) => setSelectedSymbol(e.value)}
              options={symbol_list}
              optionLabel="name"
              placeholder="Select a Symbol"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          MONTH : {selectedmonth}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedmonth}
              onChange={(e) => setSelectedMonth(e.value)}
              options={month_list}
              optionLabel="name"
              placeholder="Select a Month"
            />
          </div>
        </div>
      </div>

      <div
        style={{
          margin: 10,
          padding: 10,
          marginTop: 20,
          backgroundColor: Dark.background,
          height: 55,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 0.6, color: Dark.text, fontSize: 15 }}>
          YEAR : {selectedyear}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Dropdown
              value={selectedyear}
              onChange={(e) => setSelectedYear(e.value)}
              options={year_list}
              optionLabel="name"
              placeholder="Select a Year"
            />
          </div>
        </div>
      </div>

      <div
        onClick={loading ? null : add_symbol}
        style={{
          padding: 10,
          backgroundColor: Dark.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderRadius: 7,
          color: Dark.text,
          marginTop: 20,
        }}
      >
        {loading ? (
          <div>
            <Loading type="spin" color={Dark.text} height={20} width={20} />
          </div>
        ) : (
          "SAVE"
        )}
      </div>
    </div>
  );
}
