import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import "./Symbol.css";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import SymbolCard from "./SymbolCard";
const backend = new Backend();
export default function Admin(props) {
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState([]);
  const [symbolsearch, setSymbolSearch] = useState([]);
  const [symbolname, setSymbolName] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    loadSymbol();
  }, []);

  const loadSymbol = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.symbol_standing(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSymbol(r.symbol);
        setSymbolSearch(r.symbol);
      } else {
        toast(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
      }
    });
  };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = symbolsearch.filter((x) =>
        x.symbol.toLowerCase().includes(a.toLowerCase())
      );
      setSymbol(data);
    } else {
      setSymbol(symbolsearch);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1, width: "100%" }}>
        <div
          style={{
            height: 50,
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ paddingRight: 20, textTransform: "uppercase" }}>
            Search Symbol{" "}
          </div>
          <div className="loginInputFilterSymbol">
            <InputText
              value={search}
              onChange={(e) => {
                searchSymbol(e.target.value);
                setSearch(e.target.value);
              }}
              placeholder="Search Symbol"
            />
          </div>
          <div
            onClick={() => {
              searchSymbol("");
              setSearch("");
            }}
            style={{
              marginLeft: 20,
              backgroundColor: Dark.sell,
              padding: 8,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
            }}
          >
            CLEAR
          </div>
        </div>

        <div
          style={{
            height: "75%",
            backgroundColor: Dark.background,
            width: "100%",
            color: Dark.text,
            display: "flex",
            marginTop: 10,
            borderRadius: 10,

            flexDirection: "column",
          }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Loading type="spin" height={40} width={40} color={Dark.text} />
            </div>
          ) : symbol.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                height: "35vh",
              }}
            >
              {symbol.map((i) => {
                return (
                  <SymbolCard
                    item={i}
                    id={symbolname}
                    updateSymbol={(a) => {
                      setSymbolName(a.symbol);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Admin Found.</div>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          props.close();
          props.symbol(symbolname);
          props.updatesymbol(symbolname);
        }}
        style={{
          height: 50,
          backgroundColor: Dark.primary,
          width: "100%",
          borderRadius: 10,
          color: Dark.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        SUBMIT
      </div>
    </div>
  );
}
